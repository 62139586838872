import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/hooks/AuthContext';
import MenuRouter from '../../menu/Router';
import PMSRouter from '../../pms/Router';
import EventRouter from '../../event/Router';
import Navbar from '../components/dashboard/Navbar';
import { useEffect } from 'react';
import { trpc } from '../../../utils/trpc';
import { useTranslation } from 'react-i18next';
import { LocaleStorageItemKey, useLocalStorage } from '../utils/local-storage';
import { z } from 'zod';
import Home from './Home';
import { CompanyContextProvider, useCompanyContext } from '../contexts/CompanyContext';

async function authenticateFreshdeskJwtCallback(getFreshdeskJwt: () => Promise<string>) {
  const jwt = await getFreshdeskJwt();
  if (jwt) {
    (window as any).FreshworksWidget('authenticate', {
      token: jwt,
    });
  }
}

export default function Dashboard() {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to='/auth' />;
  }

  return (
    <WithFreshDeskAuth>
      <CompanyContextProvider>
        <div className='page'>
          <Navbar />
          <div className='page-wrapper mb-5'>
            <div className='container-xl' />
            <div className='page-body'>
              <DashboardContainer />
            </div>
          </div>
        </div>
      </CompanyContextProvider>
    </WithFreshDeskAuth>
  );
}

function DashboardContainer() {
  const lastVisitedPage = useLastVisitedPage();

  return (
    <div className='container-xl d-flex flex-column justify-content-center'>
      <CompanyMarkedForDeletionAlert />
      <Routes>
        <Route index element={<Navigate to={lastVisitedPage} />} />
        <Route path='/home' element={<Home />} />
        <Route path='/pms/*' element={<PMSRouter />} />
        <Route path='/menu/*' element={<MenuRouter />} />
        <Route path='/event/*' element={<EventRouter />} />
      </Routes>
    </div>
  );
}

function CompanyMarkedForDeletionAlert() {
  const { t, i18n } = useTranslation(['auth']);
  const { companyData } = useCompanyContext();

  if (companyData.deletedAt === null) {
    return null;
  }

  return (
    <div className='alert alert-warning' role='alert'>
      <h4 className='alert-title'>{t('auth:companyMarkedForDeletion.title')}</h4>
      <div className='text-secondary'>
        {t('auth:companyMarkedForDeletion.description', {
          date: new Date(companyData.deletedAt).toLocaleString(i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
        })}
      </div>
    </div>
  );
}

function WithFreshDeskAuth({ children }: { children: React.ReactNode }) {
  const getFreshdeskJwt = trpc.auth.getFreshdeskJwt.useQuery();

  useEffect(() => {
    if (!getFreshdeskJwt.data) return;
    const jwt = getFreshdeskJwt.data;
    (window as any).FreshworksWidget('authenticate', {
      token: jwt,
      callback: () =>
        authenticateFreshdeskJwtCallback(async () => {
          const { data: jwt } = await getFreshdeskJwt.refetch();
          if (!jwt) throw new Error('Could not get freshdesk jwt');
          return jwt;
        }),
    });
  }, [getFreshdeskJwt.data]);

  return <>{children}</>;
}

function useLastVisitedPage() {
  const location = useLocation();
  const DEFAULT_LAST_VISITED_PAGE = '/home';
  const [lastVisitedPage, setLastVisitedPage] = useLocalStorage(
    LocaleStorageItemKey.DASHBOARD_LAST_VISITED_PAGE,
    DEFAULT_LAST_VISITED_PAGE,
    z.string(),
  );

  useEffect(() => {
    if (lastVisitedPage === '/') {
      setLastVisitedPage(DEFAULT_LAST_VISITED_PAGE);
    }
  }, [lastVisitedPage]);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/') {
      return;
    }
    setLastVisitedPage(pathname);
  }, [location.pathname, setLastVisitedPage]);

  return lastVisitedPage;
}
