import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { useTranslation } from 'react-i18next';
import TimezoneInput from '../../common/components/form/TimezoneInput';
import { Event } from '../api/events';

type CreateEventFormProps = {
  action: 'create';
  eventUuid?: undefined;
  event?: undefined;
  onSuccess: ({ eventUuid }: { eventUuid: string }) => void;
  onCancel: () => void;
};
type UpdateEventFormProps = {
  action: 'update';
  eventUuid: string;
  event: Event;
  onSuccess: () => void;
  onCancel: () => void;
};
type EventFormProps = CreateEventFormProps | UpdateEventFormProps;

export default function EventForm({
  action,
  eventUuid,
  event,
  onSuccess,
  onCancel,
}: EventFormProps) {
  const { t } = useTranslation(['event']);
  const trpcContext = trpc.useUtils();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [timezone, setTimezone] = useState(
    event?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [hasMaxPaxPerEventOccurrence, setHasMaxPaxPerEventOccurrence] = useState(
    event === undefined ? false : event.occurrences[0]?.maxPaxPerEventOccurrence !== null,
  );
  const [hasMaxPaxPerResponse, setHasMaxPaxPerResponse] = useState(
    event === undefined ? false : event.occurrences[0]?.maxPaxPerResponse !== null,
  );

  const options = { invalidate: [trpcContext.event], onSuccess, setFormErrors };
  const createEvent = createMutation(trpc.event.createEvent, options);
  const updateEvent = createMutation(trpc.event.updateEvent, options);
  const deleteEvent = createMutation(trpc.event.deleteEvent, options);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.target as HTMLFormElement);
    const data = {
      name: form.get('eventName') as string,
      publicName: {},
      publicDescription: {},
      timezone,
      data: {
        occurrences: [
          {
            type: 'one-time' as const,
            date: form.get('occurrenceDate') as string,
            time: form.get('occurrenceTime') as string,
            maxPaxPerEventOccurrence: hasMaxPaxPerEventOccurrence
              ? parseInt(form.get('maxPaxPerEventOccurrence') as string, 10)
              : null,
            maxPaxPerResponse: hasMaxPaxPerResponse
              ? parseInt(form.get('maxPaxPerResponse') as string, 10)
              : null,
          },
        ] as Event['occurrences'],
      },
    };

    if (action === 'create') {
      createEvent.mutate(data);
    } else {
      updateEvent.mutate({ ...data, uuid: eventUuid });
    }
  };

  const isLoading = createEvent.isLoading || updateEvent.isLoading || deleteEvent.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <FormInput
          type='text'
          name='eventName'
          title={t('event:event.form.name')}
          errorPath={['name']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={event?.name}
        />
      </div>
      <div className='row'>
        <div className='col-6'>
          <FormInput
            type='date'
            name='occurrenceDate'
            title={t('event:event.form.occurrence.date')}
            errorPath={['data.occurrences.0.date']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={event?.occurrences[0]?.date}
          />
        </div>
        <div className='col-6'>
          <FormInput
            type='time'
            name='occurrenceTime'
            title={t('event:event.form.occurrence.time')}
            errorPath={['data.occurrences.0.time']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={event?.occurrences[0]?.time}
          />
        </div>
        <div className='col-12'>
          <TimezoneInput value={timezone} onChange={setTimezone} />
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <FormInput
            type='switch'
            name='hasMaxPaxPerEventOccurrence'
            title={t('event:event.form.occurrence.maxPaxPerEventOccurrence')}
            hint={t('event:event.form.occurrence.maxPaxPerEventOccurrenceHint')}
            errorPath={['data.occurrences.0.maxPaxPerEventOccurrence']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={hasMaxPaxPerEventOccurrence}
            checkboxValue='on'
            onChange={setHasMaxPaxPerEventOccurrence}
          />
        </div>
        <div className='col-6'>
          <FormInput
            type='switch'
            name='hasMaxPaxPerResponse'
            title={t('event:event.form.occurrence.maxPaxPerResponse')}
            hint={t('event:event.form.occurrence.maxPaxPerResponseHint')}
            errorPath={['data.occurrences.0.maxPaxPerResponse']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={hasMaxPaxPerResponse}
            checkboxValue='on'
            onChange={setHasMaxPaxPerResponse}
          />
        </div>
      </div>
      {(hasMaxPaxPerEventOccurrence || hasMaxPaxPerResponse) && (
        <div className='row'>
          <div className='col-6'>
            {hasMaxPaxPerEventOccurrence && (
              <FormInput
                type='number'
                name='maxPaxPerEventOccurrence'
                title={t('event:event.form.occurrence.maxPaxPerEventOccurrence')}
                hint={t('event:event.form.occurrence.maxPaxPerEventOccurrenceHint')}
                errorPath={['data.occurrences.0.maxPaxPerEventOccurrence']}
                formErrors={formErrors}
                disabled={isLoading}
                defaultValue={event?.occurrences[0]?.maxPaxPerEventOccurrence?.toString() ?? '1000'}
                step='1'
              />
            )}
          </div>
          <div className='col-6'>
            {hasMaxPaxPerResponse && (
              <FormInput
                type='number'
                name='maxPaxPerResponse'
                title={t('event:event.form.occurrence.maxPaxPerResponse')}
                hint={t('event:event.form.occurrence.maxPaxPerResponseHint')}
                errorPath={['data.occurrences.0.maxPaxPerResponse']}
                formErrors={formErrors}
                disabled={isLoading}
                defaultValue={event?.occurrences[0]?.maxPaxPerResponse?.toString() ?? '1000'}
                step='1'
              />
            )}
          </div>
        </div>
      )}
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger w-100'
              isLoading={deleteEvent.isLoading}
              disabled={isLoading}
              hideContentWhenLoading
              onClick={async () => {
                if (window.confirm('Are you sure?')) {
                  deleteEvent.mutate({ uuid: event.uuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
            {t('event:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            isLoading={createEvent.isLoading || updateEvent.isLoading}
            disabled={isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('event:form.create') : t('event:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
