import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Page from '../../../common/components/dashboard/Page';
import { useTranslation } from 'react-i18next';
import EventForm from '../../components/EventForm';
import { useEventContext } from '../../Context';

export default function EventPage() {
  const { t } = useTranslation(['event']);

  return (
    <Page title={t('event:events')}>
      <Routes>
        <Route
          path='*'
          element={
            <Routes>
              <Route path='/new' element={<NewEvent />} />
              <Route path='/:uuid' element={<EditEvent />} />
            </Routes>
          }
        />
      </Routes>
    </Page>
  );
}

function NewEvent() {
  const navigate = useNavigate();

  return (
    <EventForm
      action='create'
      onSuccess={() => {
        navigate(`/event`);
      }}
      onCancel={() => {
        navigate(`/event`);
      }}
    />
  );
}

function EditEvent() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { events } = useEventContext();
  const event = events.find((e) => e.uuid === uuid);

  if (event === undefined) {
    return <Navigate to='/event' />;
  }

  return (
    <EventForm
      action='update'
      eventUuid={event.uuid}
      event={event}
      onSuccess={() => {
        navigate(`/event`);
      }}
      onCancel={() => {
        navigate(`/event`);
      }}
    />
  );
}
