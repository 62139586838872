import { createContext, useContext } from 'react';
import { trpc } from '../../utils/trpc';
import ErrorAlert from '../common/components/ErrorAlert';
import LoadingSpinner from '../common/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { Event } from './api/events';

type EventContextValue = {
  events: Event[];
};

const initialState: EventContextValue = {
  events: [],
};

const EventContext = createContext<EventContextValue>(initialState);

export function EventContextProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation(['event']);
  const eventsQuery = trpc.event.getEvents.useQuery();

  if (eventsQuery.isLoading) {
    return <LoadingSpinner text={t('menu:loadingData')} center cardBody />;
  }

  if (eventsQuery.error) {
    return <ErrorAlert errors={[eventsQuery.error]} />;
  }

  return (
    <EventContext.Provider
      value={{
        events: eventsQuery.data,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}

export const useEventContext = () => useContext(EventContext);
