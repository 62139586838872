import {
  IconBook,
  IconBook2,
  IconCalendar,
  IconCalendarEvent,
  IconClockPause,
  IconHome,
  IconLanguage,
  IconList,
  IconLogout,
  IconPlus,
  IconStar,
  IconTimeline,
  IconTools,
} from '@tabler/icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../auth/hooks/AuthContext';
import { getEnabledModules } from '../../utils/configuration';
import { getAvailableLanguages, setLanguage } from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Button from '../form/Button';

export default function Navbar() {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['menu', 'pms', 'event']);
  const { logout: useLogout } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  const menuClass = showMenu ? 'collapse navbar-collapse show' : 'collapse navbar-collapse';

  const navbarItems: Array<
    | {
        type: 'item';
        name: string;
        link: string;
        icon: JSX.Element;
      }
    | {
        type: 'divider';
        name: string;
      }
  > = [];

  const enabledModules = getEnabledModules();

  if (enabledModules.pms) {
    navbarItems.push(
      {
        type: 'divider',
        name: t('pms:navbar.pms'),
      },
      {
        type: 'item',
        name: t('pms:navbar.dashboard'),
        link: '/pms',
        icon: <IconHome />,
      },
      {
        type: 'item',
        name: t('pms:navbar.bookings'),
        link: '/pms/bookings',
        icon: <IconBook2 />,
      },
      {
        type: 'item',
        name: t('pms:navbar.newBooking'),
        link: '/pms/bookings/new',
        icon: <IconPlus />,
      },
      {
        type: 'item',
        name: t('pms:navbar.calendar'),
        link: '/pms/planning',
        icon: <IconCalendar />,
      },
      {
        type: 'item',
        name: t('pms:navbar.unconfirmedBookings'),
        link: '/pms/bookings/unconfirmed',
        icon: <IconClockPause />,
      },
      {
        type: 'item',
        name: t('pms:navbar.stats'),
        link: '/pms/stats',
        icon: <IconTimeline />,
      },
      {
        type: 'item',
        name: t('pms:navbar.configuration'),
        link: '/pms/configuration',
        icon: <IconTools />,
      },
    );
  }

  if (enabledModules.menu) {
    navbarItems.push(
      {
        type: 'divider',
        name: t('menu:navbar.menu'),
      },
      {
        type: 'item',
        name: t('menu:navbar.menu'),
        link: '/menu/menus',
        icon: <IconBook />,
      },
      {
        type: 'item',
        name: t('menu:navbar.orders'),
        link: '/menu/orders',
        icon: <IconList />,
      },
      {
        type: 'item',
        name: t('menu:navbar.configuration'),
        link: '/menu/configuration',
        icon: <IconTools />,
      },
    );

    if (enabledModules.billing) {
      navbarItems.push({
        type: 'item',
        name: t('menu:navbar.plans'),
        link: '/menu/plans',
        icon: <IconStar />,
      });
    }
  }

  if (enabledModules.events) {
    navbarItems.push(
      {
        type: 'divider',
        name: t('event:navbar.events'),
      },
      {
        type: 'item',
        name: t('event:navbar.events'),
        link: '/event/events',
        icon: <IconCalendarEvent />,
      },
    );
  }

  navbarItems.push({
    type: 'divider',
    name: 'General',
  });

  return (
    <>
      <aside className='navbar navbar-vertical navbar-expand-lg' data-bs-theme='dark'>
        <div className='container-fluid'>
          <button
            className='navbar-toggler'
            type='button'
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <span className='navbar-toggler-icon' />
          </button>
          <h1 className='navbar-brand navbar-brand-autodark'>
            <Link to='/home'>SimPulse</Link>
          </h1>
          <div className='navbar-nav flex-row d-lg-none' />
          <div className={menuClass} id='navbar-menu'>
            <ul className='navbar-nav pt-lg-3'>
              {navbarItems.map((item, i) =>
                item.type === 'divider' ? (
                  <li key={`navbar-divider-${i}`}>
                    <div className='hr-text'>{item.name}</div>
                  </li>
                ) : (
                  <li key={`navbar-item-${item.link}`} className='nav-item'>
                    <Link
                      to={item.link}
                      className='nav-link'
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      <span className='nav-link-icon d-lg-inline-block'>{item.icon}</span>
                      <span className='nav-link-title'>{item.name}</span>
                    </Link>
                  </li>
                ),
              )}
              <li className='nav-item'>
                <button
                  type='button'
                  className='nav-link bg-transparent border-0 text-warning'
                  onClick={() => {
                    if (confirm('Are you sure you want to logout?')) {
                      useLogout();
                    }
                  }}
                >
                  <span className='nav-link-icon d-lg-inline-block'>
                    <IconLogout />
                  </span>
                  <span className='nav-link-title'>Logout</span>
                </button>
              </li>
              <li className='nav-item'>
                <button
                  type='button'
                  className='nav-link bg-transparent border-0'
                  onClick={() => setShowLanguageSelector(true)}
                >
                  <span className='nav-link-icon d-lg-inline-block'>
                    <IconLanguage />
                  </span>
                  <span className='nav-link-title'>Language</span>
                </button>
              </li>
              <li className='nav-item'>
                <a href='https://my.simpulse.it/' className='nav-link'>
                  <span className='nav-link-title'>Accedi a SimPulse 1.0</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      <Modal
        isOpen={showLanguageSelector}
        onClose={() => setShowLanguageSelector(false)}
        title='Language'
      >
        <div className='d-flex flex-column justify-content-center gap-2'>
          {getAvailableLanguages().map((language) => (
            <Button
              key={language.code}
              className={`btn ${i18n.language === language.code ? 'btn-primary' : ''}`}
              onClick={() => {
                setLanguage(language.code);
                setShowLanguageSelector(false);
              }}
            >
              {language.name}
            </Button>
          ))}
        </div>
      </Modal>
    </>
  );
}
