import { createContext, useContext } from 'react';
import { trpc } from '../../utils/trpc';
import ErrorAlert from '../common/components/ErrorAlert';
import LoadingSpinner from '../common/components/LoadingSpinner';
import { EasyMenuCloudPage, MenuItem, MenuItemPrice, MenuPage, MenuSection } from './api/menu-page';
import { PaymentMethod } from '../pms/api/payment-methods';
import { useTranslation } from 'react-i18next';

type MenuContextValue = {
  menuPages: MenuPage[];
  menuSections: MenuSection[];
  menuItems: MenuItem[];
  menuItemPrices: MenuItemPrice[];
  isRootSection: (sectionUuid: string) => boolean;
  paymentMethods: PaymentMethod[];
  easyMenuCloudPages: EasyMenuCloudPage[];
};

const initialState: MenuContextValue = {
  menuPages: [],
  menuSections: [],
  menuItems: [],
  menuItemPrices: [],
  isRootSection: () => false,
  paymentMethods: [],
  easyMenuCloudPages: [],
};

const MenuContext = createContext<MenuContextValue>(initialState);

export function MenuContextProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation(['menu']);
  const menuDataQuery = trpc.menu.admin.getMenuData.useQuery();
  const easyMenuCloudData = trpc.menu.admin.getEasyMenuCloudData.useQuery();
  const paymentMethodsQuery = trpc.pay.paymentMethod.listPaymentMethods.useQuery();

  if (menuDataQuery.isLoading || paymentMethodsQuery.isLoading) {
    return <LoadingSpinner text={t('menu:loadingData')} center cardBody />;
  }

  if (menuDataQuery.error || paymentMethodsQuery.error) {
    const errors = [
      ...(menuDataQuery.error ? [menuDataQuery.error] : []),
      ...(paymentMethodsQuery.error ? [paymentMethodsQuery.error] : []),
    ];
    return <ErrorAlert errors={errors} />;
  }

  const menuData = menuDataQuery.data;
  const paymentMethods = paymentMethodsQuery.data;
  const easyMenuCloudPages = easyMenuCloudData?.data?.menuPages ?? [];

  const isRootSection = (sectionUuid: string) =>
    menuData.menuPages.some((menuPage) => {
      return menuPage.rootSectionUuid === sectionUuid;
    });

  return (
    <MenuContext.Provider
      value={{
        ...menuData,
        isRootSection,
        paymentMethods,
        easyMenuCloudPages,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export const useMenuContext = () => useContext(MenuContext);
