import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
  IconAlertCircle,
  IconCheck,
  IconExternalLink,
  IconInfoCircle,
  IconPencil,
  IconPlus,
} from '@tabler/icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../common/components/Card';
import Modal from '../../../common/components/Modal';
import Page from '../../../common/components/dashboard/Page';
import Button from '../../../common/components/form/Button';
import { useMenuContext } from '../../Context';
import { EasyMenuCloudPage, MenuPage } from '../../api/menu-page';
import MenuPageForm from '../../components/MenuPageForm';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../../utils/trpc';
import { createMutation } from '../../../../utils/api';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

export default function MenuPagesPage() {
  const { t } = useTranslation(['menu']);

  return (
    <Page title={t('menu:menuPages')}>
      <NewMenuPageButton />
      <MenuPages />
      <EasyMenuCloudPages />
    </Page>
  );
}

function MenuPages() {
  const { menuPages } = useMenuContext();

  if (menuPages.length === 0) {
    return <NoMenuPagesAlert />;
  }

  return <MenuPageElements menuPages={menuPages} />;
}

function NoMenuPagesAlert() {
  const { t } = useTranslation(['menu']);
  return (
    <div className='alert alert-info' role='alert' style={{ marginTop: '1rem' }}>
      <h4 className='alert-title'>{t('menu:noMenuPagesAlert')}</h4>
    </div>
  );
}

function MenuPageElements({ menuPages }: { menuPages: MenuPage[] }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  return (
    <div ref={parentRef}>
      {menuPages.map((menuPage) => (
        <MenuPageElement key={`menu-page-element-${menuPage.uuid}`} menuPage={menuPage} />
      ))}
    </div>
  );
}

function MenuPageElement({ menuPage }: { menuPage: MenuPage }) {
  return (
    <Card style={{ marginTop: '1rem' }}>
      <div className='d-flex justify-content-between'>
        <div className='text-truncate'>{menuPage.name}</div>
        <div className='text-truncate'>
          <EditMenuPageButton menuPageUuid={menuPage.uuid} />
        </div>
      </div>
    </Card>
  );
}

function EasyMenuCloudPages() {
  const { easyMenuCloudPages: easyMenuPages } = useMenuContext();

  if (easyMenuPages.length === 0) {
    return null;
  }

  return (
    <>
      <div className='mt-4' />
      <hr />
      <div className='alert alert-warning' role='alert'>
        <h4 className='alert-title'>Azione necessaria</h4>
        <div className='text-secondary'>
          I menu sotto sono salvati sul tuo account EasyMenu.cloud e, attualmente, non possono
          essere modificati da SimPulse. EasyMenu.cloud a breve verrà dismesso. Ma non preoccuparti,
          puoi spostare i tuoi menu su SimPulse con un click! Clicca il pulsante "Copia da
          EasyMenu.cloud a SimPulse" per spostare i tuoi menu digitali.
          <br />
          <b>Tutti i codici QR continueranno a funzionare senza problemi!</b> Il sistema è
          completamente automatico. Se hai domande, non esitare a contattarci.
        </div>
      </div>
      <EasyMenuCloudPageElements menuPages={easyMenuPages} />
    </>
  );
}

function EasyMenuCloudPageElements({ menuPages }: { menuPages: EasyMenuCloudPage[] }) {
  return (
    <div>
      {menuPages.map((menuPage) => (
        <EasyMenuCloudPageElement
          key={`easy-menu-cloud-page-element-${menuPage.slug}`}
          menuPage={menuPage}
        />
      ))}
    </div>
  );
}

function EasyMenuCloudPageElement({ menuPage }: { menuPage: EasyMenuCloudPage }) {
  const { menuPages: simpulseMenus } = useMenuContext();
  const hasBeenMigrated = simpulseMenus.find((mp) => mp.slug === menuPage.slug) !== undefined;

  const trpcContext = trpc.useUtils();
  const migrate = createMutation(trpc.menu.admin.migrateFromEasyMenuCloud, {
    invalidate: [trpcContext.menu],
  });

  return (
    <Card style={{ marginTop: '1rem' }}>
      <div className='d-flex justify-content-between'>
        <div>
          <div className='text-truncate'>{menuPage.name}</div>
          <br />
          <br />
          {hasBeenMigrated ? (
            <>
              <span className='text-success font-weight-light'>
                <IconCheck size='1em' /> Copiato da EasyMenu.cloud a SimPulse.
              </span>
              <br />
              <span className='text-warning font-weight-light'>
                <IconAlertCircle size='1em' /> Nota: da ora in poi, modifica questo menu da
                SimPulse, non da EasyMenu.cloud. Tutte le modifiche effettuate su EasyMenu.cloud non
                verranno sincronizzate su SimPulse. Se vuoi, puoi eliminare il menu da
                EasyMenu.cloud.
              </span>
            </>
          ) : (
            <>
              <span className='text-secondary  font-weight-light'>
                <IconInfoCircle size='1em' /> Questo menu è salvato in EasyMenu.cloud.
              </span>
              <br />
              <br />
              <Button
                type='button'
                onClick={() => migrate.mutate(menuPage)}
                disabled={migrate.isLoading}
              >
                Copia da EasyMenu.cloud a SimPulse
              </Button>
              <br />
              {migrate.isLoading && (
                <div className='alert' role='alert'>
                  <LoadingSpinner
                    center
                    text='Copia in corso. Il processo potrebbe richiedere qualche minuto.'
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className='text-truncate'>
          <Link
            to={`https://menu.simpulse.it/${menuPage.slug}`}
            className='btn btn-icon'
            target='_blank'
          >
            <IconExternalLink size='1em' />
          </Link>
        </div>
      </div>
    </Card>
  );
}

function NewMenuPageButton() {
  const { t } = useTranslation(['menu']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type='button' onClick={() => setShowModal(true)}>
        <IconPlus /> {t('menu:newMenuPage')}
      </Button>
      <Modal
        title={t('menu:newMenuPage')}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size='sm'
      >
        <MenuPageForm
          action='create'
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
}

function EditMenuPageButton({ menuPageUuid }: { menuPageUuid: string }) {
  return (
    <Link to={`/menu/menus/edit/${menuPageUuid}`} className='btn btn-icon'>
      <IconPencil size='1em' />
    </Link>
  );
}
