import { useTranslation } from 'react-i18next';
import ConfigurationIndexItem from '../components/ConfigurationIndexItem';
import Page from '../components/dashboard/Page';
import { getEnabledModules } from '../utils/configuration';

export default function Home() {
  const { t } = useTranslation(['generic']);
  const enabledModules = getEnabledModules();

  return (
    <Page title='SimPulse'>
      <ConfigurationIndexItem to='/pms' title={t('generic:pms')} hint={t('generic:pmsHint')} />
      <ConfigurationIndexItem
        to='/menu/menus'
        title={t('generic:menu')}
        hint={t('generic:menuHint')}
      />
      {enabledModules.events && (
        <ConfigurationIndexItem
          to='/event/events'
          title={t('generic:event')}
          hint={t('generic:eventHint')}
        />
      )}
    </Page>
  );
}
