export function getEnabledModules() {
  const isProduction = process.env.REACT_APP_VERCEL_ENV === 'production';

  return {
    pms: true,
    menu: true,
    events: !isProduction,

    billing: !isProduction,
  };
}
