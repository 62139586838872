import { Navigate, Route, Routes } from 'react-router-dom';
import EventsPage from './pages/events';
import EventPage from './pages/event';
import { EventContextProvider } from './Context';

export default function Router() {
  return (
    <EventContextProvider>
      <Routes>
        <Route
          path='*'
          element={
            <Routes>
              <Route index element={<Navigate to='events' />} />
              <Route path='/events' element={<EventsPage />} />
              <Route path='/events/event/*' element={<EventPage />} />
            </Routes>
          }
        />
      </Routes>
    </EventContextProvider>
  );
}
