import Page from '../../../common/components/dashboard/Page';
import { useTranslation } from 'react-i18next';
import { useEventContext } from '../../Context';
import { IconPencil, IconPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Event } from '../../api/events';
import Card from '../../../common/components/Card';

export default function EventsPage() {
  const { t } = useTranslation(['event']);
  const { events } = useEventContext();
  return (
    <Page title={t('event:events')}>
      <NewEventButton />
      <div className='mb-3' />
      <EventElements events={events} />
    </Page>
  );
}

function NewEventButton() {
  const { t } = useTranslation(['event']);

  return (
    <Link to='./event/new' className='btn btn-primary w-100'>
      <IconPlus />
      {t('event:event.form.createNewEvent')}
    </Link>
  );
}

function EventElements({ events }: { events: Event[] }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  return (
    <div ref={parentRef}>
      {events.map((event) => (
        <EventElement key={`event-element-${event.uuid}`} event={event} />
      ))}
    </div>
  );
}

function EventElement({ event }: { event: Event }) {
  return (
    <Card style={{ marginTop: '1rem' }}>
      <div className='d-flex justify-content-between'>
        <div className='text-truncate'>{event.name}</div>
        <div className='text-truncate'>
          <EditEventButton eventUuid={event.uuid} />
        </div>
      </div>
    </Card>
  );
}

function EditEventButton({ eventUuid }: { eventUuid: string }) {
  return (
    <Link to={`event/${eventUuid}`} className='btn btn-icon'>
      <IconPencil size='1em' />
    </Link>
  );
}
